define("discourse/plugins/discourse-perspective-api/initializers/discourse-perspective", ["exports", "discourse/lib/plugin-api", "discourse/lib/ajax", "I18n", "@ember/service"], function (_exports, _pluginApi, _ajax, _I18n, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initialize(api) {
    const siteSettings = api.container.lookup("site-settings:main");
    api.modifyClass("controller:composer", {
      pluginId: "discourse-perspective-api",
      _perspective_checked: null,
      dialog: (0, _service.inject)(),
      perspectiveSave(force) {
        this.set("_perspective_checked", true);
        const result = this.save(force);

        // it's valid for save() to return null since we do that in core,
        // handle that here because sometimes we return a promise
        if (result != null && typeof result.then === "function") {
          result.finally(() => {
            this.set("disableSubmit", false);
            this.set("_perspective_checked", false);
          });
        } else {
          this.set("disableSubmit", false);
          this.set("_perspective_checked", false);
        }
      },
      save(force) {
        if (!this.siteSettings.perspective_enabled) {
          return this._super(...arguments);
        }

        // same validation code from controller
        if (this.disableSubmit && !this._perspective_checked) {
          return;
        }
        if (!this.showWarning) {
          this.set("model.isWarning", false);
        }
        const composer = this.model;
        if (composer.cantSubmitPost) {
          this.set("lastValidatedAt", Date.now());
          return;
        } else {
          // disable composer submit during perspective validation
          this.set("disableSubmit", true);
        }
        const bypassPM = !siteSettings.perspective_check_private_message && this.get("topic.isPrivateMessage");
        const bypassSecuredCategories = !siteSettings.perspective_check_secured_categories && this.get("model.category.read_restricted");
        const bypassCheck = bypassPM || bypassSecuredCategories;
        if (!bypassCheck && !this._perspective_checked) {
          return this.perspectiveCheckToxicity(composer, force);
        } else {
          this.set("disableSubmit", false);
          return this._super(force);
        }
      },
      perspectiveCheckToxicity(composer, force) {
        let concat = "";
        ["title", "raw", "reply"].forEach(item => {
          const content = composer.get(item);
          if (content) {
            concat += `${content} `;
          }
        });
        concat.trim();
        return (0, _ajax.ajax)("/perspective/post_toxicity", {
          type: "POST",
          data: {
            concat
          }
        }).then(response => {
          if (response && response["score"] !== undefined) {
            this.dialog.confirm({
              confirmButtonLabel: "perspective.composer_edit",
              confirmButtonClass: "btn-primary perspective-edit-post",
              didConfirm: () => {
                if (this.isDestroying || this.isDestroyed) {
                  return;
                }
                this.set("disableSubmit", false);
              },
              message: _I18n.default.t("perspective.perspective_message"),
              cancelButtonLabel: "perspective.composer_continue",
              cancelButtonClass: "perspective-continue-post",
              didCancel: () => this.perspectiveSave(force)
            });
            return;
          } else {
            this.perspectiveSave(force);
          }
        }).catch(() => {
          // fail silently
          this.perspectiveSave(force);
        });
      }
    });
  }
  var _default = _exports.default = {
    name: "discourse-perspective-api",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (siteSettings.perspective_enabled && siteSettings.perspective_notify_posting_min_toxicity_enable) {
        (0, _pluginApi.withPluginApi)("0.8.17", initialize);
      }
    }
  };
});